import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "performers-page__content" }
const _hoisted_2 = {
  key: 0,
  class: "performer-select-page__suggested"
}
const _hoisted_3 = { class: "performers-page__search mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PerformerGrid = _resolveComponent("PerformerGrid")!
  const _component_PerformerSearchForm = _resolveComponent("PerformerSearchForm")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Model Creators Gallery'),
    class: "performers-page mb-24",
    "back-button": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.recommendedPerformersLoading || !_ctx.isEmpty(_ctx.recommendedPerformers))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_a_typography_title, { level: 5 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("We suggest these for your brand")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_PerformerGrid, {
                "performers-list": _ctx.recommendedPerformers ?? [],
                loading: _ctx.recommendedPerformersLoading,
                onOnItemClick: _ctx.handlePerformerClick
              }, null, 8, ["performers-list", "loading", "onOnItemClick"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_PerformerSearchForm, {
            filters: _ctx.performersFilter,
            loading: _ctx.performersLoading || _ctx.performersFetchMoreLoading,
            onOnEnterButton: _ctx.handleSearch,
            onOnSearch: _ctx.handleSearch,
            "search-on-keystroke": "",
            class: "mb-10"
          }, null, 8, ["filters", "loading", "onOnEnterButton", "onOnSearch"]),
          _createVNode(_component_PerformerGrid, {
            "performers-list": _ctx.performers ?? [],
            loading: _ctx.performersLoading,
            "has-next-page": _ctx.performersHasNextPage,
            "load-more-loading": _ctx.performersFetchMoreLoading,
            onOnLoadMore: _ctx.performersFetchMore,
            onOnItemClick: _ctx.handlePerformerClick
          }, null, 8, ["performers-list", "loading", "has-next-page", "load-more-loading", "onOnLoadMore", "onOnItemClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}