
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import PerformerGrid from "@/shared/components/Performers/PerformerGrid.vue";
import PerformerSearchForm from "@/shared/components/Performers/PerformerSearchForm.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { usePerformers } from "@/shared/composables/usePerformers";
import { useRecommendedPerformers } from "@/shared/composables/useRecommendedPerformers";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { galleryPlaceholderSrc } from "@/shared/utils/constants";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { defineComponent, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  // For keep-alive
  name: routeNames.performers,
  setup() {
    const { t } = useI18n();
    const { redirectToPerformerDetails } = useRedirectHelper();

    // Recommended Performers
    const {
      recommendedPerformersFilter,
      recommendedPerformers,
      recommendedPerformersLoading,
    } = useRecommendedPerformers({});

    const { businessProfile } = useBusinessProfile();

    watchEffect(() => {
      if (businessProfile.value && businessProfile.value.industry) {
        performersFilter.industryId = Number(businessProfile.value.industry.id);
      }
    });

    // Performer Search
    const {
      performersFilter,
      performers,
      performersLoading,
      performersFetchMore,
      performersFetchMoreLoading,
      performersHasNextPage,
    } = usePerformers();

    const handleSearch = (query: string) => {
      performersFilter.query = query;
    };

    // === Handlers ===
    const handlePerformerClick = (id: string) => {
      console.log("handlePerformerSelect: ", id);
      redirectToPerformerDetails({ id });
    };

    return {
      t,
      isEmpty,
      handleSearch,
      performersFilter,
      performers,
      performersLoading,
      performersFetchMore,
      performersFetchMoreLoading,
      performersHasNextPage,
      recommendedPerformersFilter,
      recommendedPerformers,
      recommendedPerformersLoading,
      handlePerformerClick,
      galleryPlaceholderSrc,
    };
  },
  components: {
    PageLayout,
    PerformerGrid,
    PerformerSearchForm,
  },
});
